.navbar {
  font-family: 'Source Sans Pro', sans-serif;
}

.main-block-header {
  font-family: 'Source Sans Pro', sans-serif;
}

.h3 {
  font-family: 'Source Sans Pro', sans-serif;
}

.item-h1 {
  font-family: 'Source Sans Pro', sans-serif;
}

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    margin-top: 100px;
    border-radius: 10px;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
    box-shadow: 41px 41px 100px #d1d1d1, -41px -41px 100px #ffffff;
    padding: 30px;
    border-radius: 20px;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
}
